import { useCallback, useEffect, useRef } from 'react';

import careerMainButtonClick from '@hh.ru/analytics-js-events/build/career_platform/main/career_main_button_click';
import careerMainCoursesElementShown from '@hh.ru/analytics-js-events/build/career_platform/main/career_main_courses_element_shown';
import { translation, TranslatedComponent } from '@hh.ru/front-static-app';
import { Button, Title, useBreakpoint } from '@hh.ru/magritte-ui';
import { useSelectorNonNullable } from '@hh.ru/redux-create-reducer';
import { Link } from '@hh.ru/redux-spa-middleware';

import { CourseFilterParams } from 'src/app/routesUtils/courseFilterParams';
import CardCourse from 'src/components/CardCourse/CardCourse';
import Section from 'src/pages/Index/components/Section';
import { COURSES_SECTION_ID } from 'src/pages/Index/constants';
import getDataQa from 'src/utils/getDataQa';

import styles from './styles.less';

const TrlKeys = {
    title: 'career-platform.landing.courses-section.title',
    button: 'career-platform.landing.courses-section.button',
};

const DATA_QA_BLOCK = 'courses-section';

const CoursesSection: TranslatedComponent = ({ trls }) => {
    const { courses } = useSelectorNonNullable((state) => state.landing);

    const { isXS, isS, isM } = useBreakpoint();
    const isMobile = isXS || isS;

    const sectionRef = useRef<HTMLElement>(null);

    useEffect(() => {
        if (sectionRef.current) {
            careerMainCoursesElementShown(sectionRef.current);
        }
    }, []);

    const getList = useCallback(() => {
        if (isMobile) {
            return courses.slice(0, 3);
        }
        if (isM) {
            return courses.slice(0, 6);
        }
        return courses;
    }, [courses, isM, isMobile]);

    return (
        <Section ref={sectionRef} id={COURSES_SECTION_ID} dataQa={getDataQa(DATA_QA_BLOCK)}>
            <div className={styles.title}>
                <Title
                    size={isMobile ? 'medium' : 'large'}
                    alignment={'center'}
                    Element="h2"
                    dataQaTitle={getDataQa(DATA_QA_BLOCK, 'title')}
                >
                    {trls[TrlKeys.title]}
                </Title>
            </div>
            <div className={styles.list}>
                {getList().map((item) => (
                    <CardCourse key={item.id} course={item} />
                ))}
            </div>
            <Button
                mode="secondary"
                size="large"
                stretched
                Element={Link}
                to={CourseFilterParams.getCoursesHref()}
                onClick={() => careerMainButtonClick({ buttonName: 'all_courses' })}
                data-qa={getDataQa(DATA_QA_BLOCK, 'show-all-courses')}
            >
                {trls[TrlKeys.button]}
            </Button>
        </Section>
    );
};

export default translation(CoursesSection);
