import cardCourseButtonClick from '@hh.ru/analytics-js-events/build/career_platform/courses/card_course_button_click';
import { translation, TranslatedComponent } from '@hh.ru/front-static-app';
import { Button } from '@hh.ru/magritte-ui';

import { CoursePurpose, CourseTypeFollowLink, EducationPurpose, Purpose } from 'src/types/courses';
import { analytics, Goal } from 'src/utils/analytics';
import openNewBlankTab from 'src/utils/openNewBlankTab';

const TrlKeys = {
    button: {
        toCourse: 'career-platform.components.button-link-external-course.button.toCourse',
        toProgram: 'career-platform.components.button-link-external-course.button.toProgram',
    },
};

interface CardCourseButtonLinkExternalProps {
    course: CourseTypeFollowLink;
}

export interface PatchUtmContentConfig {
    providerIds: string[];
    appendValue: string;
}

export const cardCourseButtonLinkExternalHandler = (course: CourseTypeFollowLink): void => {
    const link = course.link;

    // так обходим блокировку контента со стороны adBlock,
    // abBlock большинство ссылок от партнеров считает рекламными и скрывает за display: none
    openNewBlankTab(link);
    analytics.reach(Goal.click_profession_guide_go_to_course);
    cardCourseButtonClick({
        courseName: course.name,
        streamName: course?.stream?.name,
        providerId: course?.provider?.id || null,
        providerName: course?.provider?.name || null,
        cardType: course.purpose,
        professionId: course.profession?.id || null,
        coursePrice: course.priceDetails.providerPromotionAmount || course.priceDetails.amountPerMonth || 0,
        priceDetails: JSON.stringify(course.priceDetails),
        courseLink: link,
    });
};

const purposeToKeyMapping: Record<Purpose, string> = {
    [CoursePurpose.Skill]: TrlKeys.button.toCourse,
    [CoursePurpose.Profession]: TrlKeys.button.toCourse,
    [EducationPurpose.HigherEducation]: TrlKeys.button.toProgram,
    [EducationPurpose.SpecializedSecondaryEducation]: TrlKeys.button.toProgram,
};

const CardCourseButtonLinkExternal: TranslatedComponent<CardCourseButtonLinkExternalProps> = ({ course, trls }) => {
    return (
        <Button
            style="neutral"
            mode="primary"
            size="small"
            stretched
            onClick={() => cardCourseButtonLinkExternalHandler(course)}
        >
            {trls[purposeToKeyMapping[course.purpose]]}
        </Button>
    );
};

export default translation(CardCourseButtonLinkExternal);
